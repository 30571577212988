import { useState, useCallback, useEffect, useMemo } from 'react'
import { parseEther, formatEther } from 'ethers'
import BigNumber from 'bignumber.js'
import cx from 'classnames'
import { useDepositMusharakah } from 'hooks/useRealEstateToken'
import { useDepositMusharakahEstimate } from 'hooks/useRealEstateToken'
import { useUserDetails, useUserBalanceNative } from 'state/user/hooks'

import BuyTokenInput from 'components/UI/BuyTokenInput'

import { useIsCorrectAddress, useIsWalletConnected } from 'state/user/hooks'
import { useActiveNetwork } from 'state/network/hooks'

import { formatNative, fromDoubleEther, formatCompleteNative } from 'utils/formatNumber'

import ErrorBuyBanner from 'components/UI/ErrorBuyBanner'

import styles from './styles.module.scss'
import SuccessBuyBanner from 'components/UI/SuccessBuyBanner'
import GlobalPreloader from 'components/UI/GlobalPreloader'

const BuyBackMusharakahForm = ({ tokenInfo }) => {
  const activeNetwork = useActiveNetwork()
  const isCorrectAddress = useIsCorrectAddress()
  const isWalletConnected = useIsWalletConnected()
  // console.log('tokenInfo ->', tokenInfo)

  const onDepositMusharakahEstimate = useDepositMusharakahEstimate(tokenInfo.contract_address)
  const balanceNative = useUserBalanceNative()
  const user = useUserDetails()

  const [dnrAmount, setDnrAmount] = useState('')
  const [tokenAmount, setTokenAmount] = useState('')
  const [estimatedTxCost, setEstimatedTxCost] = useState(BigInt(0))

  const [formError, setFormError] = useState('')
  const [successPurchase, setSuccessPurchase] = useState(false)

  const tokenPrice = BigInt(tokenInfo.price)
  const hardcap = BigInt(tokenInfo.hardcap)
  // const totalSupply = BigInt(tokenInfo.total_supply)
  const maxBuyAmount = BigInt(tokenInfo.max_buy_amount)
  const minBuyAmount = BigInt(tokenInfo.min_buy_amount)
  const musharakahMinAmount = (hardcap * 3n) / 100n

  const { onDepositMusharakah, loading } = useDepositMusharakah(tokenInfo.contract_address, user.eth_address)

  const musharakahTokensLeft = BigInt(tokenInfo.musharakah_tokens_left)

  // function calcMaxAmountToBuy(maxLimit, availableTokens) {
  //   if (availableTokens <= maxLimit) {
  //     return availableTokens
  //   } else {
  //     return maxLimit
  //   }
  // }

  let canBuy = useMemo(() => {
    setFormError('')
    if (
      tokenAmount === '' ||
      tokenAmount === '0' ||
      !tokenPrice ||
      loading ||
      !isCorrectAddress ||
      !activeNetwork
    ) {
      return false
    }

    const amount = parseEther(tokenAmount)

    if (amount > musharakahTokensLeft) {
      setFormError('Your amount is greater than the number of tokens left')
      return false
    }

    // if (amount < musharakahMinAmount) {
    //   setFormError('Your amount is less than the minimum purchase quantity')
    //   return false
    // }

    if (
      musharakahTokensLeft > 0n &&
      amount > musharakahTokensLeft
    ) {
      setFormError('Your value is greater than the maximum purchase quantity')
      return false
    }
    console.log(balanceNative, estimatedTxCost)
    if (parseEther(dnrAmount) > balanceNative - estimatedTxCost) {
      setFormError('Insufficient balance to purchase tokens')
      return false
    }
    return true
  }, [
    dnrAmount,
    tokenAmount,
    balanceNative,
    estimatedTxCost,
    minBuyAmount,
    musharakahTokensLeft,
    maxBuyAmount,
    tokenPrice,
    musharakahMinAmount,
    loading,
  ])

  useEffect(() => {
    async function estimate() {
      if (
        !tokenAmount ||
        (musharakahTokensLeft > 0n &&
        parseEther(tokenAmount) > musharakahTokensLeft) ||
        parseEther(dnrAmount) > BigInt(balanceNative)
      ) {
        setEstimatedTxCost(BigInt(0))
        return
      }
      const cost = await onDepositMusharakahEstimate(parseEther(dnrAmount || '0'))
      setEstimatedTxCost(cost || 0n)
    }

    estimate()
  }, [
    dnrAmount,
    minBuyAmount,
    maxBuyAmount,
    musharakahTokensLeft,
    balanceNative,
    onDepositMusharakahEstimate,
  ])

  const isValidNumberInput = (input) => {
    return input.match(/^\d*\.?\d*$/) != null && input !== '.'
  }

  const calculateDNRAmount = useCallback(amount => {
    if (!isValidNumberInput(amount)) {
      return
    }

    let validAmount = amount
    if (amount.endsWith('.')) {
      validAmount = amount.slice(0, -1)
    }

    const amt = new BigNumber(parseEther(validAmount)).div(new BigNumber(tokenPrice))
    const roundedAmt = new BigNumber(amt.toFixed(7, BigNumber.ROUND_DOWN))
    setDnrAmount(amount)
    setTokenAmount(roundedAmt.toString())
  }, [tokenPrice, balanceNative])

  const calculateTokenAmount = useCallback(amount => {
    if (!isValidNumberInput(amount)) {
      return
    }

    let validAmount = amount
    if (amount.endsWith('.')) {
      validAmount = amount.slice(0, -1)
    }

    const amt = new BigNumber(parseEther(validAmount)).times(new BigNumber(tokenPrice)).div(new BigNumber(1e36))
    const roundedAmt = new BigNumber(amt.toFixed(7, BigNumber.ROUND_UP))
    setTokenAmount(amount)
    setDnrAmount(roundedAmt.toString())
  }, [tokenPrice])

  const setMaxDnrAmount = useCallback(async () => {
    const cost = await onDepositMusharakahEstimate(BigInt(balanceNative))
    calculateDNRAmount(formatEther(BigInt(balanceNative) - cost))
  }, [balanceNative, calculateDNRAmount, onDepositMusharakahEstimate])

  const setMaxTokenAmount = useCallback(() => {
    calculateTokenAmount(formatEther(musharakahTokensLeft))
  }, [maxBuyAmount, musharakahTokensLeft, calculateTokenAmount])

  // const handleDNRAmountChange = useCallback(event => {
  //   const amount = event.target.value
  //   if (!event.target.validity.valid) {
  //     return
  //   }
  //   if (amount === '') {
  //     setDnrAmount('')
  //     setTokenAmount('')
  //     return
  //   }

  //   calculateDNRAmount(amount)
  // }, [calculateDNRAmount])

  // const handleTokenAmountChange = useCallback(event => {
  //   const amount = event.target.value
  //   if (!event.target.validity.valid) {
  //     return
  //   }

  //   if (amount === '') {
  //     setDnrAmount('')
  //     setTokenAmount('')
  //     return
  //   }

  //   calculateTokenAmount(amount)
  // }, [calculateTokenAmount])

  const handleDNRAmountChange = useCallback(
    (event) => {
      let { value } = event.target

      if (value === '.') {
        value = '0.'
      } else if (!/^\d*\.?\d{0,3}$/.test(value)) {
        return
      }

      if (value === '') {
        setDnrAmount('')
        setTokenAmount('')
      } else {
        setDnrAmount(value)
        calculateDNRAmount(value)
      }
    },
    [calculateDNRAmount],
  )

  const handleTokenAmountChange = useCallback(
    (event) => {
      let { value } = event.target

      if (value === '.') {
        value = '0.'
      } else if (!/^\d*\.?\d{0,3}$/.test(value)) {
        return
      }

      // if (value === '.') {
      //   value = '0.'
      // } else if (!/^\d*\.?\d*$/.test(value)) {
      //   return
      // }

      if (value === '') {
        setDnrAmount('')
        setTokenAmount('')
      } else {
        setTokenAmount(value)
        calculateTokenAmount(value)
      }
    },
    [calculateTokenAmount],
  )

  const handleDepositMusharakah = useCallback(async () => {
    if (!canBuy) {
      return
    }
    console.log('token amount: ', tokenAmount)
    console.log('dnr amount: ', dnrAmount)
    console.log('tokenPrice', tokenPrice)
    try {
      const reciept = await onDepositMusharakah(
        parseEther(dnrAmount),
      )
      if (reciept.status === 1) {
        // success transaction
        setSuccessPurchase(true)
      }
    } catch (error) {
      //Metamask error
      console.log(error)
    }
  }, [
    tokenAmount,
    tokenPrice,
    canBuy,
  ])

  const renderFirstError = () => {
    if (!isWalletConnected) {
      return <ErrorBuyBanner label="Wallet not connected" desc="" />
    }
    if (!isCorrectAddress) {
      return (
        <ErrorBuyBanner
          label="Your wallet address does not match your account"
          desc="When registering, each user adds his wallet address and only with the help of it can he perform all financial transactions. Please switch to your wallet address that you specified when registering in your Metamask."
        />
      )
    }
    if (!activeNetwork) {
      return (
        <ErrorBuyBanner
          label="You are using the wrong network"
          desc="Our application uses Edifice's own blockchain. Please switch to the Edifice network in your Metamask settings."
        />
      )
    }
    if (
      balanceNative / tokenPrice <= fromDoubleEther(minBuyAmount)
    ) {
      return (
        <ErrorBuyBanner
          label="Insufficient funds for minimum purchase"
          desc={`The minimum quantity to purchase a ${
            tokenInfo.symbol
          } token is ${fromDoubleEther(
            minBuyAmount,
          )}. Taking into account the price, you need an amount of ${
            fromDoubleEther(minBuyAmount) * fromDoubleEther(tokenPrice)
          } DNR to make a minimal purchase. You can buy more DNR at this page.`}
        />
      )
    }
    // if (
    //   balanceNative / tokenPrice <= fromDoubleEther(musharakahMinAmount)
    // ) {
    //   return (
    //     <ErrorBuyBanner
    //       label="Insufficient funds for minimum purchase"
    //       desc={`The minimum quantity to purchase a ${
    //         tokenInfo.symbol
    //       } token is ${formatNative(
    //         musharakahMinAmount,
    //       )}. Taking into account the price, you need an amount of ${formatNative(
    //         musharakahMinAmount * fromDoubleEther(tokenPrice),
    //       )} DNR to make a minimal purchase. You can buy more DNR at this page.`}
    //     />
    //   )
    // }
    return null
  }

  return (
    <div className={styles.wrapper}>
      {loading && <GlobalPreloader />}
      {successPurchase && (
        <SuccessBuyBanner text="The purchase of tokens was successful. They have been sent your wallet. You can check your balance both in Metamask and on the Wallet page" />
      )}
      <div className={styles.formIntit}>
        {' '}
        {renderFirstError()}
        <div className={styles.buyForm}>
          {(
            <h4>
              Buying back a
              {' '}
              <span>{tokenInfo.symbol}</span>
              {' '}
              token
            </h4>
          )}
          <p className={styles.description}></p>
          <div className={styles.priceBanner}>
            <div className={styles.additionalWrapper}>
              <p className={styles.price}>
                {`1 ${tokenInfo.symbol} = ${formatNative(tokenPrice)} DNR`}
              </p>
              <p className={styles.description}>Current price</p>
            </div>
            <div className={styles.additionalWrapper}>
              <p className={styles.price}>
                {`${formatNative(musharakahTokensLeft)} ${
                  tokenInfo.symbol
                } = ${formatNative(
                  fromDoubleEther(musharakahTokensLeft * tokenPrice),
                )} DNR`}
              </p>
              <p className={styles.description}>Maximum purchase</p>
            </div>
            <div className={styles.additionalWrapper}>
              {(
                <p className={styles.price}>
                  {`${formatNative(minBuyAmount)} ${
                    tokenInfo.symbol
                  } = ${formatNative(
                    fromDoubleEther(minBuyAmount * tokenPrice),
                  )} DNR`}
                </p>
              )}
              <p className={styles.description}>Minimum purchase</p>
            </div>
            <hr />
          </div>
          <div className={styles.formWrapper}>
            <BuyTokenInput
              label="Cost"
              name="dnrAmount"
              value={dnrAmount}
              onChange={handleDNRAmountChange}
              staticLabelText="Your balance"
              actionLabelText={`${formatNative(balanceNative)} DNR`}
              actionLabelFunc={setMaxDnrAmount}
            />

            <BuyTokenInput
              label="Please specify the number of tokens"
              name="tokenAmount"
              value={tokenAmount}
              onChange={handleTokenAmountChange}
              staticLabelText="Remaining tokens"
              actionLabelText={
                formatCompleteNative(musharakahTokensLeft) +
                ' ' +
                tokenInfo.symbol
              }
              actionLabelFunc={setMaxTokenAmount}
            />

            {formError && <p className={styles.formError}>{formError}</p>}
            <div className={styles.actionBtnWrapper} onClick={handleDepositMusharakah}>
              <p className={cx(styles.btn, { [styles.disabled]: !canBuy })}>
                {`Buy ${tokenInfo.symbol} token`}
              </p>
            </div>
            {/* { isCorrectAddress ? (<p className={styles.feeInfo}>Address correct</p>) : (<p className={styles.feeInfo}>Address not correct</p>) } */}
            <p className={styles.feeInfo}>
              {`Transaction fees: ~ ${formatNative(estimatedTxCost, 8)} DNR`}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BuyBackMusharakahForm

import { useState, useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import { parseEther } from 'ethers'

import OrderService from 'API/OrderService'

import useInterval from './useInterval'
import useCatchTxError from './useCatchTxError'
import { useExchangeContract, useRealEstateTokenContract } from './useContract'

import { formatNative } from 'utils/formatNumber'

export function useActiveOrders() {
  const [orders, setOrders] = useState([])

  const update = useCallback(async () => {
    const resp = await OrderService.ordersList()
    setOrders(resp)
  }, [])

  useInterval(update, 5000)

  return orders
}

export function useOrder(orderId) {
  const [order, setOrder] = useState(undefined)

  const update = useCallback(async () => {
    const resp = await OrderService.order(orderId)
    setOrder(resp)
  }, [orderId])

  useInterval(update, 5000)

  return order
}

export function useCreateOrder(tokenAddress) {
  const { account } = useWeb3React()
  const contract = useExchangeContract()

  const { loading, fetchWithCatchTxError } = useCatchTxError()

  const onCreateOrder = useCallback(async (amount, price) => {
    if (!account || !contract || !amount || !price) {
      return
    }

    const receipt = await fetchWithCatchTxError(() => {
      return contract.createOrder(tokenAddress, amount, price)
    }, {
      message: 'Creating order',
    })

    return receipt
  }, [account, contract, tokenAddress])

  return { loading, onCreateOrder }
}

export function useCancelOrder(orderId) {
  const { account } = useWeb3React()
  const contract = useExchangeContract()
  const { loading, fetchWithCatchTxError } = useCatchTxError()

  const onCancelOrder = useCallback(async () => {
    if (!account || !contract) {
      return
    }

    const receipt = await fetchWithCatchTxError(() => {
      return contract.cancelOrder(orderId)
    }, {
      message: `Canceling order ${orderId}`,
    })

    return receipt
  }, [account, contract, orderId])

  return { loading, onCancelOrder }
}

export function useBuyOrder(orderId) {
  const { account } = useWeb3React()
  const contract = useExchangeContract()
  const { loading, fetchWithCatchTxError } = useCatchTxError()

  const onBuyOrder = useCallback(async (amount) => {
    if (!account || !contract || !amount) {
      return
    }

    const receipt = await fetchWithCatchTxError(() => {
      return contract.buy(orderId, { value: parseEther(amount) })
    }, {
      message: `Buying order for ${amount} DNR`,
    })

    return receipt
  }, [account, contract, orderId])

  return { loading, onBuyOrder }
}

export function useActiveSellSharesOrders() {
  const [orders, setOrders] = useState([])

  const update = useCallback(async () => {
    const data = await OrderService.fetchSellShareOrders()
    setOrders(data)
  }, [])

  useInterval(update, 5000)

  return orders
}

export function useCreateSellSharesOrder(tokenAddress) {
  const contract = useRealEstateTokenContract(tokenAddress)
  const { loading, fetchWithCatchTxError } = useCatchTxError()

  const onCreateSellSharesOrder = useCallback(async (amount, price) => {
    if (!contract) {
      return
    }

    const receipt = await fetchWithCatchTxError(() => {
      return contract.createInvestorSellSharesOrder(amount, price, 0)
    }, {
      message: `Creating sell shares order for ${formatNative(amount)} by ${formatNative(price)} DNR`,
    })

    return receipt
  }, [contract, fetchWithCatchTxError])

  return { loading, onCreateSellSharesOrder }
}

export function useCancelSellSharesOrder(tokenAddress) {
  const contract = useRealEstateTokenContract(tokenAddress)
  const { loading, fetchWithCatchTxError } = useCatchTxError()

  const onCancelSellSharesOrder = useCallback(async () => {
    if (!contract) {
      return
    }

    const receipt = await fetchWithCatchTxError(() => {
      return contract.cancelInvestorSellSharesOrder()
    }, {
      message: 'Canceling sell shares order',
    })

    return receipt
  }, [contract, fetchWithCatchTxError])

  return { loading, onCancelSellSharesOrder }
}

export function useBuySellSharesOrder(tokenAddress, fromInvestorAddress) {
  const { account } = useWeb3React()
  const contract = useRealEstateTokenContract(tokenAddress)
  const { loading, fetchWithCatchTxError } = useCatchTxError()

  const onBuyOrder = useCallback(async (amount) => {
    if (!account || !contract || !amount) {
      return
    }

    const receipt = await fetchWithCatchTxError(() => {
      return contract.buyInvestorShares(fromInvestorAddress, { value: parseEther(amount) })
    }, {
      message: `Buying order for ${amount} DNR`,
    })

    return receipt
  }, [account, contract, fromInvestorAddress])

  return { loading, onBuyOrder }
}

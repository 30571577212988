import { useMemo } from 'react'
import { useWeb3React } from '@web3-react/core'

import { ConnectionType } from 'web3/connection'
import { isSupportedChain } from 'web3/utils'
import { getContract } from 'web3/utils'
import { useGetConnection } from 'web3/connection'

import { ADDRESS_REGISTRY_ADDRESS, EXCHANGE_ADDRESS } from 'constants'

import ADDRESS_REGISTRY_ABI from 'constants/abis/addressRegistry'
import REAL_ESTATE_TOKEN_ABI from 'constants/abis/edificeXToken'
import EXCHANGE_ABI from 'constants/abis/exchange'
import { useActiveChainId } from 'state/network/hooks'
import GOVERNANCE_ABI from 'constants/abis/governance'

export function useContract(address, ABI, withSignerIfPossible = true) {
  const { provider, account, chainId } = useWeb3React()
  const getConnection = useGetConnection()

  return useMemo(() => {
    if (!address || !ABI || !provider) {
      return null
    }

    const networkConnection = getConnection(ConnectionType.NETWORK)
    const networkConnector = networkConnection.connector
    const isValidChain = isSupportedChain(chainId)

    try {
      // Use network connector if the chain is not supported
      if (!isValidChain && networkConnector.customProvider) {
        return getContract(
          address,
          ABI,
          networkConnector.customProvider,
          undefined,
        )
      }
      return getContract(
        address,
        ABI,
        provider,
        withSignerIfPossible && account ? account : undefined,
      )
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [
    address,
    ABI,
    provider,
    withSignerIfPossible,
    account,
    chainId,
    getConnection,
  ])
}

export function useRealEstateTokenContract(address) {
  return useContract(address, REAL_ESTATE_TOKEN_ABI)
}

export function useAddressRegistryContract() {
  const chainId = useActiveChainId()
  return useContract(ADDRESS_REGISTRY_ADDRESS[chainId], ADDRESS_REGISTRY_ABI)
}

export function useExchangeContract() {
  const chainId = useActiveChainId()
  return useContract(EXCHANGE_ADDRESS[chainId], EXCHANGE_ABI)
}

export function useGovernanceContract(address) {
  return useContract(address, GOVERNANCE_ABI)
}

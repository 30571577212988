import { useState } from 'react'
import styles from './styles.module.scss'
import { Link } from 'react-router-dom'
import { useUserVotes } from 'state/user/hooks'
import { useMemo } from 'react'

const getUserVoteResultText = (votes) => {
  switch (votes) {
    case 0:
      return 'Against'
    case 1:
      return 'For'
    case 2:
      return 'Abstain'
    default:
      return '-'
  }
}

const getTotalVotesResultText = (votesFor, votesAgainst, votesAbstain) => {
  const [forNumber, againstNumber, abstainNumber] = [
    votesFor,
    votesAgainst,
    votesAbstain,
  ].map(Number)

  const maxVotes = Math.max(forNumber, againstNumber, abstainNumber)

  if (maxVotes === forNumber) return 'For'
  if (maxVotes === againstNumber) return 'Against'
  if (maxVotes === abstainNumber) return 'Abstain'
}

const TokenProposals = ({ tokenProposals }) => {
  const [isOpened, setIsOpened] = useState(false)
  const votes = useUserVotes()

  const toggleIsOpened = (e) => {
    e.stopPropagation()
    setIsOpened((current) => !current)
  }

  const openMenu = () => {
    setIsOpened(true)
  }

  const isActiveProposals = useMemo(
    () =>
      tokenProposals.some(
        (proposal) =>
          proposal.proposal_state === 'active' &&
          !votes?.some(
            (vote) => vote.proposal_chain_id === proposal.proposal_id,
          ),
      ),
    [tokenProposals, votes],
  )
  // console.log('tokenProposals', tokenProposals)
  return (
    <div>
      <div className={styles.proposal} onClick={openMenu}>
        <div className={styles.content}>
          <img
            src={tokenProposals[0]?.token_sale?.thumbnail?.url}
            alt="thumbnail"
          />
          <div>
            <h3>{tokenProposals[0].token_sale.title}</h3>
            <p>{tokenProposals[0].token_sale.address.address}</p>
          </div>
        </div>
        <button
          onClick={toggleIsOpened}
          aria-label="View Proposal"
          aria-expanded={isOpened}
          className={styles.viewProposal}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
          >
            <path
              d="M9 13.5L18 22.5L27 13.5"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>

      {isOpened && (
        <div className={styles.proposals}>
          <table className={styles.proposalsTable}>
            <tr>
              <th>Proposal</th>
              <th>Your Vote</th>
              <th>Overall Vote</th>
            </tr>
            {tokenProposals.map((proposal) => (
              <tr key={proposal.proposal_id}>
                <td>{proposal.description}</td>
                <td>
                  {getUserVoteResultText(
                    votes?.find(
                      (vote) => vote.proposal_chain_id === proposal.proposal_id,
                    )?.support,
                  )}
                </td>
                <td>
                  {!['active', 'pending'].includes(proposal.proposal_state)
                    ? getTotalVotesResultText(
                        proposal.for_votes,
                        proposal.against_votes,
                        proposal.abstain_votes,
                      )
                    : '-'}
                </td>
              </tr>
            ))}
          </table>

          {isActiveProposals && (
            <Link
              to={`/governance?token_address=${tokenProposals[0].token_sale.token_info.contract_address}`}
              className={styles.voteButton}
            >
              Vote
            </Link>
          )}
        </div>
      )}
    </div>
  )
}

export default TokenProposals

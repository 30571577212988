import { ChainId } from 'web3/chains'

export const ADDRESS_REGISTRY_ADDRESS = {
  [ChainId.EDIFICE]: '',
  [ChainId.TESTNET]: '',
  [ChainId.LOCAL]: '0x1c85638e118b37167e9298c2268758e058DdfDA0',
}

export const EXCHANGE_ADDRESS = {
  [ChainId.EDIFICE]: '',
  [ChainId.EDIFICE_TESTNET]: '',
  [ChainId.LOCAL]: '0xA4899D35897033b927acFCf422bc745916139776',
}
export function filterGroupedTransactions(transactionsByDate) {
  // Копируем объект, чтобы избежать мутации исходных данных
  const result = {}

  // Итерация по каждому ключу даты
  Object.keys(transactionsByDate).forEach(date => {
    const transactions = transactionsByDate[date]
    const hashTracker = {}
    const filteredTransactions = []

    // Перебираем транзакции для текущей даты
    transactions.forEach(transaction => {
      if (!hashTracker[transaction.hash]) {
        hashTracker[transaction.hash] = transaction
      } else {
        // Если текущая транзакция типа "buy_tokens", обновляем запись в hashTracker
        if (transaction.type === 'buy_tokens') {
          hashTracker[transaction.hash] = transaction
        }
      }
    })

    // Переводим объект с уникальными транзакциями обратно в массив
    for (const hash in hashTracker) {
      filteredTransactions.push(hashTracker[hash])
    }

    // Добавляем отфильтрованные транзакции в результат
    result[date] = filteredTransactions
  })

  return result
}

export function filterTransactions(data) {
  // Создаем копию данных, чтобы не изменять исходный массив
  const dataCopy = JSON.parse(JSON.stringify(data))

  // Проходим по каждому объекту в копии массива
  dataCopy.forEach(token => {
    const uniqueTransactions = []
    const hashTracker = {}

    // We go through each transaction.
    token.transactions.forEach(transaction => {
      // If the transaction hash has not been encountered yet, we add it to the temporary object.
      const mapKey = transaction.hash+transaction.type+transaction.data.amount+transaction.data.from+transaction.data.to
      if (!hashTracker[mapKey]) {
        hashTracker[mapKey] = transaction
      } else {
        // If a transaction with this hash already exists and the current transaction is of type ‘buy_tokens’, we update the information.
        if (transaction.type === 'buy_tokens') {
          hashTracker[mapKey] = transaction
        }
      }
    })

    // Converting an object with unique transactions back into an array
    for (const hash in hashTracker) {
      uniqueTransactions.push(hashTracker[hash])
    }

    // Обновляем список транзакций для каждого токена
    token.transactions = uniqueTransactions
  })

  return dataCopy
}

import moment from 'moment'

import { formatNative } from 'utils/formatNumber'
import BigNumber from 'bignumber.js'

import arrowDownImg from 'assets/images/icons/arrow-down-green.svg'
import tokenLogo from 'assets/images/icons/edifice-logo.svg'
import completedIcon from 'assets/images/icons/completed.svg'
import pendingIcon from 'assets/images/icons/pending.svg'
import errorIcon from 'assets/images/icons/error.svg'
import exchangeIcon from 'assets/images/icons/exchange.svg'

import styles from './styles.module.scss'

const statusMapping = {
  confirmed: {
    icon: completedIcon,
    text: 'Completed',
    backgroundColor: '#61E99F',
  },
  pending: {
    icon: pendingIcon,
    text: 'Pending',
    backgroundColor: '#D6D6D6',
  },
  error: {
    icon: errorIcon,
    text: 'Error',
    backgroundColor: '#D48A8A',
  },
}

const Transaction = ({ transaction, tokenSymbol, tokenPrice }) => {
  const dnrAmount = (transaction.data.amount) ? BigInt(tokenPrice) * BigInt(transaction.data.amount) / BigInt(1e18) : 0

  // for deposit_musharakah data.amount is the DNR amount.
  const amt = new BigNumber(transaction.data.amount).div(
    new BigNumber(tokenPrice),
  )
  const tokenAmount = new BigNumber(amt).toFixed(5, BigNumber.ROUND_DOWN).toString(10)

  const renderType = () => {
    if (transaction.type === 'buy_tokens') {
      return 'Buy'
    } else if (transaction.type === 'refund_tokens') {
      return 'Refund'
    } else if (transaction.type === 'transfer') {
      return 'Transfer'
    } else if (transaction.type === 'claim_rent') {
      return 'Claim Rent'
    } else if (transaction.type === 'transfer_in') {
      return 'Received'
    } else if (transaction.type === 'transfer_out') {
      return 'Sent'
    } else if (transaction.type === 'deposit_musharakah') {
      return 'Token Redeem'
    } else if (transaction.type === 'deposit_rent') {
      return 'Rent Deposite'
    } else if (transaction.type === 'claim_deposits') {
      return 'Deposite Claim'
    } else if (transaction.type === 'claim_musharakah') {
      return 'Tokens BuyBack'
    } else if (transaction.type === 'fulfill_order') {
      return 'Fulfill Order'
    } else if (transaction.type === 'cancel_order') {
      return 'Cancel Order'
    } else if (transaction.type === 'create_order') {
      return 'Create Order'
    } else if (transaction.type === 'create_sell_shares_order') {
      return 'Create Share Order'
    }


    return ''
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.leftSection}>
        <div className={styles.info}>
          <div className={styles.iconWrapper}>
            {transaction.type === 'buy_tokens' ? (
              <img src={arrowDownImg} alt="" />
            ) : (
              <img src={exchangeIcon} alt="" />
            )}
          </div>
          <div className={styles.txtData}>
            <p className={styles.type}>{renderType()}</p>
            <p className={styles.date}>{moment(transaction.created_at).format('DD/MM/YYYY hh:mm A')}</p>
          </div>
        </div>

        {transaction.type === 'buy_tokens' ? (
          <div className={styles.primaryAsset}>
            <div className={styles.iconWrapper}>
              {transaction.iconUrl ? (
                <img src={transaction.iconUrl} alt="" />
              ) : (
                <img src={tokenLogo} alt="" />
              )}
            </div>
            <div className={styles.txtData}>
              <p className={styles.amount}>
                {`${formatNative(transaction.data.amount, 5)} ${tokenSymbol}`}
              </p>
              <p className={styles.dnrAmount}>
                {`${formatNative(dnrAmount, 5)} DNR`}
              </p>
            </div>
          </div>
        ) : (
          <div className={styles.assetsExchnage}>
            {transaction.type === 'deposit_musharakah' && <div className={styles.primaryAsset}>
              <div className={styles.iconWrapper}>
                {transaction.iconUrl ? (
                  <img src={transaction.iconUrl} alt="" />
                ) : (
                  <img src={tokenLogo} alt="" />
                )}
              </div>
                {console.log('tokenAmount ->', tokenPrice)}
              <div className={styles.txtData}>
                <p className={styles.amount}>
                  {`${tokenAmount} ${tokenSymbol}`}
                </p>
                <p className={styles.dnrAmount}>
                  {`${formatNative(transaction.data.amount, 5)} DNR`}
                </p>
              </div>
            </div>}
            {transaction.type === 'transfer_in' && <div className={styles.primaryAsset}>
              <div className={styles.iconWrapper}>
                {transaction.iconUrl ? (
                  <img src={transaction.iconUrl} alt="" />
                ) : (
                  <img src={tokenLogo} alt="" />
                )}
              </div>

              <div className={styles.txtData}>
                <p className={styles.amount}>
                  {`${formatNative(transaction.data.amount, 5)} ${tokenSymbol}`}
                </p>
                <p className={styles.dnrAmount}>
                  {`${formatNative(dnrAmount, 5)} DNR`}
                </p>
              </div>
            </div>}
            {transaction.type === 'deposit_rent' && <div className={styles.primaryAsset}>
              <div className={styles.iconWrapper}>
                {transaction.iconUrl ? (
                  <img src={transaction.iconUrl} alt="" />
                ) : (
                  <img src={tokenLogo} alt="" />
                )}
              </div>

              <div className={styles.txtData}>
                <p className={styles.amount}>
                  {`${formatNative(transaction.data.amount, 5)} DNR`}
                </p>
              </div>
            </div>}
            {transaction.type === 'claim_rent' && <div className={styles.primaryAsset}>
              <div className={styles.iconWrapper}>
                {transaction.iconUrl ? (
                  <img src={transaction.iconUrl} alt="" />
                ) : (
                  <img src={tokenLogo} alt="" />
                )}
              </div>

              <div className={styles.txtData}>
                <p className={styles.amount}>
                  {`${formatNative(transaction.data.amount, 5)} DNR`}
                </p>
              </div>
            </div>}
            {transaction.type === 'transfer_out' && <div className={styles.primaryAsset}>
              <div className={styles.iconWrapper}>
                {transaction.iconUrl ? (
                  <img src={transaction.iconUrl} alt="" />
                ) : (
                  <img src={tokenLogo} alt="" />
                )}
              </div>

              <div className={styles.txtData}>
                <p className={styles.amount}>
                  {`${formatNative(transaction.data.amount, 5)} ${tokenSymbol}`}
                </p>
              </div>
            </div>}
            {transaction.type === 'claim_musharakah' && <div className={styles.primaryAsset}>
              <div className={styles.iconWrapper}>
                {transaction.iconUrl ? (
                  <img src={transaction.iconUrl} alt="" />
                ) : (
                  <img src={tokenLogo} alt="" />
                )}
              </div>
              <div className={styles.txtData}>
                <p className={styles.amount}>
                  {`${formatNative(transaction.data.token_amount, 5)} ${tokenSymbol}`}
                </p>
                <p className={styles.dnrAmount}>
                  {`${formatNative(transaction.data.amount, 5)} DNR`}
                </p>
              </div>
            </div>}
            {transaction.type === 'create_sell_shares_order' && <div className={styles.primaryAsset}>
              <div className={styles.iconWrapper}>
                {transaction.iconUrl ? (
                  <img src={transaction.iconUrl} alt="" />
                ) : (
                  <img src={tokenLogo} alt="" />
                )}
              </div>

              <div className={styles.txtData}>
                <p className={styles.amount}>
                  {`${formatNative(transaction.data.amount, 5)} ${tokenSymbol}`}
                </p>
                <p className={styles.dnrAmount}>
                  {`Price: ${formatNative(transaction.data.price, 5)} DNR`}
                </p>
              </div>
            </div>}
          </div>
        )}
      </div>
      <div className={styles.rightSection}>
        {transaction.status && (
          <div
            style={{
              backgroundColor:
                statusMapping[transaction.status].backgroundColor,
            }}
            className={styles.statusWrapper}
          >
            <img
              className={styles.statusImg}
              src={statusMapping[transaction.status].icon}
              alt=""
            />
            <p className={styles.statusTxt}>
              {statusMapping[transaction.status].text}
            </p>
          </div>
        )}

        <a
          className={styles.transactionLink}
          target="_blank"
          href={`https://explorer-testnet.edifice.network/tx/${transaction.hash}`}
          rel="noreferrer"
        >
          {`${transaction.hash.slice(0,8)}...${transaction.hash.slice(-8)}`}
        </a>
      </div>
      <div className={styles.mobileSection}>
        <a
          className={styles.mobLink}
          target="_blank"
          href={`https://explorer-testnet.edifice.network/tx/${transaction.hash}`}
          rel="noreferrer"
        >
          View on exlorer
        </a>
      </div>
    </div>
  )
}

export default Transaction

import { useMemo, useCallback } from 'react'
import { NavLink } from 'react-router-dom'

import useInterval from 'hooks/useInterval'
import MusharakahPayments from './MusharakahPayments'
import RentPayments from './RentPayments'

import {
  useUpdateProposals,
  useUserBalanceNative,
  useUserProposals,
} from 'state/user/hooks'
import {
  useUserDetails,
  useUserTransactions,
  useUserOrders,
  useUserSellSharesOrders,
  useUserTransactionsGrouppedByDate,
  useUpdateUserTransactions,
  useUpdateUserOrders,
  useUpdateUserSellSharesOrders,
} from 'state/user/hooks'

import DetailsTabsUi from 'components/UI/DetailsTabsUi'

import { formatNative } from 'utils/formatNumber'
import { filterTransactions, filterGroupedTransactions } from 'utils/filters'

import WalletImg from 'assets/images/wallet_img.svg'
import QrCodeImg from 'assets/images/icons/qr-code.svg'
import TradeImg from 'assets/images/icons/trade.svg'
import SellTokens from 'assets/images/icons/sell-ownership-tokens.svg'

import OverviewSection from './OverviewSection'
// import DefiSection from './DefiSection'
import TransactionsSection from './TransactionsSection'
import OrdersSection from './OrdersSection'

import styles from './styles.module.scss'
import GovernanceSection from './GovernanceSection'

const Portfolio = () => {
  const updateUserTransactions = useUpdateUserTransactions()
  const updateUserOrders = useUpdateUserOrders()
  const updateUserSellSharesOrders = useUpdateUserSellSharesOrders()
  const updateProposals = useUpdateProposals()

  const balanceNative = useUserBalanceNative()
  const userDetails = useUserDetails()
  const userTransactions = useUserTransactions()
  const userOrders = useUserOrders()
  const userSellSharesOrders = useUserSellSharesOrders()
  const grouppedTransactions = useUserTransactionsGrouppedByDate()
  const proposals = useUserProposals()
  // console.log('user proposals ->', proposals)

  const updateData = useCallback(async () => {
    await Promise.all([
      updateUserTransactions(),
      updateUserOrders(),
      updateUserSellSharesOrders(),
      updateProposals(),
    ])
  }, [
    updateUserTransactions,
    updateUserOrders,
    updateUserSellSharesOrders,
    updateProposals,
  ])

  useInterval(updateData, 5000)

  function shortenEthereumAddress(address, length = 4) {
    return `${address.substring(0, 2 + length)}...${address.substring(
      address.length - length,
    )}`
  }

  const tabs = [
    {
      label: 'Overview',
      content: useMemo(
        () => (
          <OverviewSection
            tokenTransactions={filterTransactions(userTransactions)}
          />
        ),
        [userTransactions],
      ),
    },
    {
      label: 'Redeem Tokens',
      content: <MusharakahPayments />,
    },
    {
      label: 'Rent Payments',
      content: <RentPayments />,
    },
    {
      label: 'Orders',
      content: useMemo(
        () => (
          <OrdersSection
            userOrders={userOrders}
            userSellSharesOrders={userSellSharesOrders}
          />
        ),
        [userOrders, userSellSharesOrders],
      ),
    },
    {
      label: 'Transactions',
      content: useMemo(
        () => (
          <TransactionsSection
            grouppedTransactions={filterGroupedTransactions(
              grouppedTransactions,
            )}
          />
        ),
        [grouppedTransactions],
      ),
    },
    {
      label: 'Governance',
      content: useMemo(
        () => <GovernanceSection proposals={proposals} />,
        [proposals],
      ),
    },
  ]

  return (
    <div className={styles.wrapper}>
      <div className={styles.topSection}>
        <div className={styles.walletInfo}>
          <div className={styles.imgWrapper}>
            <img src={WalletImg} alt="" />
          </div>
          <div className={styles.txtInfo}>
            <div className={styles.label}>Net Worth</div>
            <div className={styles.netValue}>
              {formatNative(balanceNative)}
              DNR
            </div>
            <div className={styles.address}>
              <a
                target="_blank"
                href={`${
                  'https://explorer-testnet.edifice.network/address/' +
                  userDetails.eth_address
                }`}
                rel="noreferrer"
              >
                <p className={styles.onlyDesktop}>{userDetails.eth_address}</p>
                <p className={styles.onlyMobile}>
                  {shortenEthereumAddress(userDetails.eth_address, 5)}
                </p>
              </a>
            </div>
          </div>
        </div>
        <div className={styles.actionBtns}>
          <NavLink to="/create-ownership-tokens-order" className={styles.btn}>
            <img src={SellTokens} alt="" />
            <p>Sell the right to tokens</p>
          </NavLink>
          <NavLink to="/create-order" className={styles.btn}>
            <img src={QrCodeImg} alt="" />
            <p>Create order</p>
          </NavLink>
          <NavLink to="/p2p-exchange" className={styles.btn}>
            <img src={TradeImg} alt="" />
            <p>Trade</p>
          </NavLink>
        </div>
      </div>
      <div className={styles.tabContent}>
        <DetailsTabsUi tabs={tabs} />
      </div>
    </div>
  )
}

export default Portfolio
